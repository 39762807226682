import { API } from "../../modules/Api.js";
import Logger from "../../modules/Logger";
import { setAuth } from "./loginAction.js";
import { changeChatBoxUser } from "./chatBoxAction";

export const START_OBTAIN_PROFILE_DATA = "START_OBTAIN_PROFILE_DATA";
export const OBTAIN_PROFILE_DATA = "OBTAIN_PROFILE_DATA";
export const OBTAIN_PROFILE_ERROR = "OBTAIN_PROFILE_ERROR";

function startObtainProfileData() {
  return {
    type: START_OBTAIN_PROFILE_DATA,
  };
}

function obtainProfileData(data) {
  return {
    type: OBTAIN_PROFILE_DATA,
    payload: data,
  };
}

function obtainProfileError(error) {
  return {
    type: OBTAIN_PROFILE_ERROR,
    payload: error,
  };
}

// Get profile
export const getProfile = () => (dispatch) => {
  dispatch(startObtainProfileData());
  return API.get("/auth/profile").then(
    (response) => {
      Logger.info(response);
      dispatch(setAuth());
      const profile = {
        id: response.data.id,
        account_type: response.data.account_type,
        avatar: response.data.avatar,
        name: response.data.name,
        clinic: response.data.clinic,
        phone_number: response.data.phone_number,
        country: response.data.country,
        shipping_address: response.data.shipping_address,
      };
      dispatch(obtainProfileData(profile));
      if (profile.account_type !== "owner") {
        dispatch(changeChatBoxUser(1));
      }
    },
    (error) => {
      Logger.info(error);
      dispatch(setAuth(true));
      dispatch(obtainProfileError(error));
    },
  );
};

// Update profile
export const updateProfile =
  ({ name, clinic, phone_number, country, shipping_address }) =>
  (dispatch) => {
    return API.put("auth/profile", {
      name,
      clinic,
      phone_number,
      country,
      shipping_address,
    }).then(
      (response) => {
        Logger.info(response);
        dispatch(obtainProfileData(response.data));
      },
      (error) => {
        Logger.info(error);
        dispatch(obtainProfileError(error));
      },
    );
  };
