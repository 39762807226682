export const OBTAIN_SIDEBAR_CONTACTS_DATA = "OBTAIN_SIDEBAR_CONTACTS_DATA";
export const OBTAIN_SIDEBAR_CONTACTS_ERROR = "OBTAIN_SIDEBAR_CONTACTS_ERROR";
export const OBTAIN_CONTACTS_DATA = "OBTAIN_CONTACTS_DATA";
export const OBTAIN_CONTACTS_ERROR = "OBTAIN_CONTACTS_ERROR";

function obtainSidebarContactsData(contactsList, nextCursor) {
  return {
    type: "OBTAIN_SIDEBAR_CONTACTS_DATA",
    payload: {
      contactsList: contactsList,
      nextCursor: nextCursor,
    },
  };
}

export const markasreadSidebarContacts = (contactId) => (dispatch, getState) => {
  let contactsList = [
    ...getState().contacts.contactsSidebarList.map((el) => (el.id === contactId ? { ...el, unread_pm: 0 } : el)),
  ];
  const nextCursor = getState().contacts.contactsSidebarListNextCursor;
  dispatch(obtainSidebarContactsData(contactsList, nextCursor));
};
