export const TOGGLE_REFINEMENT_DIALOG = "TOGGLE_REFINEMENT_DIALOG";
export const TOGGLE_REPLACEMENT_DIALOG = "TOGGLE_REPLACEMENT_DIALOG";
export const TOGGLE_DELETE_DIALOG = "TOGGLE_DELETE_DIALOG";
export const TOGGLE_REPLACEMENT_REQUEST_DIALOG = "TOGGLE_REPLACEMENT_REQUEST_DIALOG";
export const TOGGLE_CONFIRM_DIALOG = "TOGGLE_CONFIRM_DIALOG";
export const TOGGLE_WARRANTY_DIALOG = "TOGGLE_WARRANTY_DIALOG";
export const TOGGLE_UPLOAD_STL_DIALOG = "TOGGLE_UPLOAD_STL_DIALOG";
export const TOGGLE_UPLOAD_SHIPING_DIALOG = "TOGGLE_UPLOAD_SHIPING_DIALOG";
export const TOGGLE_IMAGE_PREVIEW_DIALOG = "TOGGLE_IMAGE_PREVIEW_DIALOG";
export const TOGGLE_CHANGE_EMAIL_DIALOG = "TOGGLE_CHANGE_EMAIL_DIALOG";
export const TOGGLE_CHANGE_PASSWORD_DIALOG = "TOGGLE_CHANGE_PASSWORD_DIALOG";
export const TOGGLE_CLOSE_DIALOG = "TOGGLE_CLOSE_DIALOG";

// Image Preview
function setImagePreview({ isShow, imagePreviewArray, imagePreviewIndex }) {
  return {
    type: TOGGLE_IMAGE_PREVIEW_DIALOG,
    payload: { isShow, imagePreviewArray, imagePreviewIndex },
  };
}

export const toggleImagePreview =
  ({ isShow, imagePreviewArray, imagePreviewIndex }) =>
  (dispatch, getState) => {
    console.log("setImagePreview", isShow, imagePreviewArray, imagePreviewIndex);
    if (isShow) {
      dispatch(setImagePreview({ isShow, imagePreviewArray, imagePreviewIndex }));
    } else {
      dispatch(setImagePreview({ isShow, imagePreviewArray, imagePreviewIndex }));
    }
  };

export function toggleChangeEmailDialog(isShow = false) {
  return {
    type: TOGGLE_CHANGE_EMAIL_DIALOG,
    payload: isShow,
  };
}
export function toggleChangePasswordDialog(isShow = false) {
  return {
    type: TOGGLE_CHANGE_PASSWORD_DIALOG,
    payload: isShow,
  };
}
