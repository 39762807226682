export const SET_CARDS_USER = "SET_CARDS_USER";
export const SET_CARD_SENDING = "SET_CARD_SENDING";
export const SET_CARDS_LIST_CANCEL_TOKEN = "SET_CARDS_LIST_CANCEL_TOKEN";
export const START_OBTAIN_CARDS_LIST = "START_OBTAIN_CARDS_LIST";
export const OBTAIN_CARDS_LIST_DATA = "OBTAIN_CARD_LIST_DATA";
export const OBTAIN_CARDS_LIST_ERROR = "OBTAIN_CARD_LIST_ERROR";

export function setCardsUser(userId) {
  return {
    type: SET_CARDS_USER,
    payload: userId,
  };
}
