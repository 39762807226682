import React, { lazy, useEffect } from "react";
import { useSelector } from "react-redux";
import { Switch, Redirect } from "react-router";
import { reconnectSocket } from "./modules/Socket";
import { PrivateRoute, OpenRoute } from "./Routes";
import "./App.scss";

const LoginLayout = lazy(() => import(/* webpackChunkName: "LoginLayout" */ "./layouts/LoginLayout"));
const MainLayout = lazy(() => import(/* webpackChunkName: "MainLayout" */ "./layouts/MainLayout"));

const App = () => {
  const { isAuth, userId } = useSelector(({ login, profile }) => ({
    isAuth: login.isAuth,
    userId: profile.id,
  }));

  useEffect(() => {
    reconnectSocket(userId);
  }, [userId]);

  return (
    <div>
      <Switch>
        <PrivateRoute path="/invoices" component={MainLayout} auth={isAuth} />
        <PrivateRoute path="/support" component={MainLayout} auth={isAuth} />
        <PrivateRoute path="/contacts" component={MainLayout} auth={isAuth} />
        <PrivateRoute path="/profile" component={MainLayout} auth={isAuth} />
        <PrivateRoute path="/assessment" component={MainLayout} auth={isAuth} />
        <OpenRoute path="/" component={LoginLayout} />
        <Redirect from="*" to="/" />
      </Switch>
    </div>
  );
};

export default App;
